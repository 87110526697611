<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" align="center">
        <h2 class="my-7">عقد العمل</h2>
        <v-card flat outlined class="pa-5">
          <v-row>
            <v-col md="6">
              <v-text-field
                append-icon="mdi-pencil"
                outlined
                dense
                v-model="name"
                placeholder="اسم العقد"
              ></v-text-field>
              <v-select
                placeholder="شهري|أسبوعي"
                :items="selectType"
                dense
                v-model="selectedType"
                outlined
              ></v-select>
              <v-select
                outlined
                dense
                placeholder="الوردية"
                :items="selectShift"
                item-text="name"
                v-model="selectedShift"
              ></v-select>

              <v-text-field
                placeholder="ساعات العمل في اليوم"
                outlined
                dense
                v-model="workHours"
              ></v-text-field>
              <v-text-field
                placeholder="عدد الاجزات بالسنة "
                outlined
                dense
                v-model="YearlyVacations"
              ></v-text-field>
              <v-autocomplete
              dense
              outlined
              :items="accountItems"
              :loading="accountLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="ContractSelect"
              label="حساب الرواتب"
              @keypress="fetchAutoCompleteAccount($event)"
            >
            </v-autocomplete>
              <v-select
                v-model="rules"
                :items="rulesList"
                label="قواعد الراتب"
                multiple
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col md="6" class="pa-5">
              <h3 class="my-2">نسبة خصم التأخير</h3>
              <v-text-field
                dense
                outlined
                class="mt-7"
                placeholder="0-100 %"
                v-model="decrease"
                append-icon="mdi-percent-outline"
              ></v-text-field>
              <h3 class="my-2">نسبة الإضافي</h3>
              <v-text-field
                dense
                outlined
                class="mt-7"
                placeholder="0-100 %"
                v-model="increase"
                append-icon="mdi-percent-outline"
              ></v-text-field>
              <br />
              <br />
              <v-btn color="success" @click="submit" class="mx-11">{{
                $t("save")
              }}</v-btn>
              <v-btn color="erorr" @click="cancel">{{ $t("cancel") }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import debounce from "../../../../../helpers/debounce";
export default {
  data() {
    return {
      accountItems: [],
      accountLoading: false,
      name: "",
      selectType: ["شهري", "أسبوعي"],
      selectShift: [],
      SalaryAccount: [],
      selectedType: "شهري",
      selectedShift: "",
      workHours: "",
      YearlyVacations: "",
      decrease: "",
      increase: "",
      rulesList: [],
      rules: [],
      rules2: [],
      ContractSelect: "",
    };
  },
  methods: {
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      await axios.get("/hr/rule").then((res) => {
        this.rules2 = res.data.rules;
      });
      for (let i = 0; i < this.rules.length; i++) {
        for (let j = 0; j < this.rules2.length; j++) {
          if (this.rules[i] == this.rules2[j].name) {
            this.rules[i] = {
              id: this.rules2[j].id,
            };
          }
        }
      }
      var shift_id = 0;
      for (let i = 0; i < this.selectShift.length; i++) {
        if ((this.selectShift[i].name = this.selectedShift)) {
          shift_id = this.selectShift[i].id;
        }
      }
      var type_key = 0;
      if (this.selectedType == "شهري") {
        type_key = "monthly";
      } else {
        type_key = "weekly";
      }
      
      if (this.$route.params.id == "Create_new_contract") {
        var rules = this.rules;
        axios
          .post("/hr/contract", {
            contract: {
              name: this.name,
              shift_id: shift_id,
              type: type_key,
              hours_in_day: this.workHours,
              leaves_in_year: this.YearlyVacations,
              extra_percent: this.increase,
              discount_percent: this.decrease,
              account_id: this.ContractSelect,
              rules: this.rules,
            },
            rules,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              (this.name = ""),
                (this.selectedType = ""),
                (this.selectedShift = ""),
                (this.workHours = ""),
                (this.YearlyVacations = ""),
                (this.decrease = ""),
                (this.increase = ""),
                this.$Notifications(
                  "تمت اضافة عقد عمل جديد بنجاح",
                  { rtl: true, timeout: 2500 },
                  "success"
                );
            }
          });
      } else {
        axios
          .put(`/hr/contract/${this.$route.params.id}`, {
            contract: {
              name: this.name,
              shift_id: shift_id,
              type: type_key,
              hours_in_day: this.workHours,
              leaves_in_year: this.YearlyVacations,
              extra_percent: this.increase,
              discount_percent: this.decrease,
              account_id: this.ContractSelect,
            },
            rules: this.rules,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              this.$Notifications(
                "تم تعديل عقد العمل  بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
            }
          });
      }
    },
  },
  async created() {
    await axios.get("/hr/shift").then((res) => {
      this.selectShift = res.data.shifts;
    });
    await axios.get("/hr/rule").then((res) => {
      this.rulesList = res.data.rules;
      for (let i = 0; i < this.rulesList.length; i++) {
        this.rulesList[i] = this.rulesList[i].name;
      }
    });
    
    if (this.$route.params.id != "Create_new_contract") {
      try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete?query=1"
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } 
      await axios.get(`/hr/contract/${this.$route.params.id}`).then((res) => {
        this.YearlyVacations = res.data.data.leaves_in_year;
        this.name = res.data.data.name;
        this.increase = res.data.data.extra_percent;
        this.workHours = res.data.data.hours_in_day;
        this.decrease = res.data.data.discount_percent;
        this.selectedShift = res.data.data.shift.name;
        if (res.data.data.type == "weekly") {
          this.selectedType = "أسبوعي";
        } else {
          this.selectedType = "شهري";
        }
        for (let i = 0; i < res.data.data.rules.length; i++) {
          for (let j = 0; j < this.rulesList.length; j++) {
            if (res.data.data.rules[i].name == this.rulesList[j]) {
              this.rules.push(this.rulesList[j]);
            }
          }
        }
        this.ContractSelect = res.data.data.account.id;
      });
    }
  },
};
</script>